.l-container {
    width: 80%;
    margin-left: 10%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding-top: 10px;
    height: 100%;
}

.rule-input {
    display: inline;
}

.rule-label {
    display: inline;
}

.form-container {
    display: flex;
    flex-direction: column;
    flex: 0 0 25%;
    max-width: 25%;
    min-width: 200px;
}

.canvas-wrapper {
    flex: 0 0 75%;
    max-width: 75%;
}

.rule-container {
    margin-top: 10px;
}

.add-rule {
    margin-top: 10px;
}

#canvas {
    border: 1px solid #4361A1;
    margin-top: 5px;
    margin-left: 5px;
}
