.boids-input {
    display: block;
}

.boids-container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
}

.sliders {
    display: flex;
    flex-direction: column;
}

#predator-label {
    margin-right: 5px;
}
