.header {
    width: 100%;
    height: auto;
    min-height: 50px;
    background-color: #4361A1;
    position: fixed;
    top: 0;
}

.nav-wrapper {
    min-height: 50px;
}

.nav-bar-inline {
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    min-height: 50px;
    max-width: 100vw;
}

.nav-component {
    color: #D8D4F2;
    padding-left: 20px;
    padding-right: 20px;
    display: flex;
    align-items: center;
    height: 100%;
    text-decoration: none;
    border-bottom: 2px solid #4361A1;
    min-height: 50px;
}

.nav-bar-stacked {
    display: none;
    flex-direction: row;
    align-items: center;
    min-height: 50px;
    justify-content: center;
}

.nav-toggle {
    display: none;
}

.nav-component-stacked {
    width: 100%;
}


.nav-toggle-zone {
    visibility: hidden;
    margin-top: -100%;
    align-items: flex-start;
    flex-direction: column;
    transition: margin-top 0s, visibility 0s;
}

.nav-toggle-zone-enabled {
    transition: margin-top 1s, visibility 1s;
    margin-top: 0;
    visibility: visible;
}

@media only screen and (max-width: 600px) {
    .nav-bar-inline {
        display: none;
    }

    .nav-bar-stacked {
        display: flex;
    }

    .nav-toggle {
        display: inherit;
        margin-left: auto;
    }
}

.nav-component:hover {
    color: #5beb42;
    text-decoration: none;
}

.nav-component-active {
    color: #5beb42;
    border-bottom: 2px solid #5beb42;
}
