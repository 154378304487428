.popover-container {
    position: fixed;
    top: 0;
    right: -33%;
    height: 100%;
    width: 33%;
    background-color: #588157;
    visibility: hidden;
    opacity: 0;
    transition: visibility 1s, opacity 1s, right 1s;
    padding: 20px;
    color: #D8D4F2;
    overflow-y: auto;
}

@media only screen and (max-width: 1000px) {
    .popover-container {
        width: 50%;
    }
}

@media only screen and (max-width: 600px) {
    .popover-container {
        width: 100%;
    }
}

.info-button {
    border-radius: 50%;
    width: 2em;
}

.popover-container a {
    color: #4361A1;
    text-decoration: underline;
}

.close-popover {
    cursor: pointer;
}

.popover-container select, .popover-container input {
    margin-left: 10px;
}
