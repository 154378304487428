body {
  background-color: #333F47;
  color: #D8D4F2;
}

.fill-container {
  background-color: #333F47;
  min-height: 88vh;
}

button {
  border-radius: revert;
}
