.page {
}

.page-content {
    min-height: calc(100vh - 50px);
    margin-top: 50px;
}

.ca-container {
    padding-top: 15px;
    width: 80%;
    margin-left: 10%;
}

.controls-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-top: 15px;
}

.controls-container label {
    margin-bottom: 0;
    margin-right: 0.25rem;
    margin-left: 0.5rem;
}

.controls-container input[type=range] {
    margin-right: 0.5rem;
}

